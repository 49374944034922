import React from 'react';
import Button from './Button';
import { makeStyles } from "@material-ui/core/styles";
import { authSelector, loginButton, logoutButton } from 'store/slices/auth/auth'; 
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from "react-router-dom";
import PeopleIcon from "@material-ui/icons/People";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useDispatch, useSelector } from 'react-redux'; 
import ListItem from "@material-ui/core/ListItem";   

const useStyles = makeStyles(theme => ({
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        cursor: 'pointer',
        fontSize:'100%' 
    },
    icons: {
        width: "20px",
        height: "20px",
        marginRight: "14px"
    },
    dropdownIcons: {
        width: "24px",
        height: "24px",
        marginRight: "14px",
        opacity: "0.5",
        marginTop: "-4px",
        top: "1px",
        verticalAlign: "middle",
        fontSize: "24px",
        position: "relative"
    },
    listItem: {
        color: "inherit"
    },
    listLink: {
        "&,&:hover,&:focus": {
            color: "inherit",
            textDecoration: "none",
            display: "flex",
            padding: "0.15rem 0.25rem 0.15rem 0.0rem"
        }
    },
    dropdownLink: {
        "&,&:hover,&:focus": {
            color: "inherit",
            textDecoration: "none",
            display: "flex",
            padding: "0.75rem 1.25rem 0.75rem 0.75rem"
        }
    },
    navButton: {
        position: "relative",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        lineHeight: "20px",
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex",
        transition: 'transform .3s ease-out',
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 30px)",
            marginLeft: "15px",
            marginBottom: "5px",
            marginTop: "5px",
            textAlign: "left",
            "& > span:first-child": {
                justifyContent: "flex-start"
            }
        },
        "& $icons": {
            marginRight: "3px"
        },
        '&:hover': {
            transform: 'translate(0,-3px)'
        }
    },
}));

const LoginList = props => {
    const classes = useStyles()
    const { authenticated, loggingInToAuth, loggingInToApp, user } = useSelector(authSelector);
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(logoutButton())
    }
    const login = () => {
        dispatch(loginButton())
    }
    const loading = loggingInToApp || loggingInToAuth;

    return (
        <>
            {!authenticated &&
                <Button
                    color={window.innerWidth < 960 ? "info" : "white"}
                    target="_blank"
                    className={classes.navButton}
                    onClick={loading ? null : login}
                    round
                >
                    <AccountCircleIcon className={classes.icons} />
                    {(!loggingInToAuth && !loggingInToApp) &&
                        "Login"
                    }
                    {(loggingInToAuth && !loggingInToApp) &&
                        "..."
                    }
                    {(!loggingInToAuth && loggingInToApp) &&
                        "... ..."
                    }
                </Button>
            }
            {(authenticated && !loading) &&
                <>
                <ListItem className={classes.listItem}>
                    {user.name}
                </ListItem>

                <ListItem className={classes.listItem}>
                    <Link
                        to="/profile-page"
                        component={RouterLink}
                        className={classes.listLink}
                    >
                        <AccountCircleIcon className={classes.dropdownIcons} />
                        Account
                    </Link>
                </ListItem>

                <ListItem className={classes.listItem}>
                    <Link
                        to="/users-page"
                        component={RouterLink}
                        className={classes.listLink}>
                        <PeopleIcon className={classes.dropdownIcons} />
                        Manage Users
                    </Link>
                </ListItem>

                <ListItem className={classes.listItem}>
                    <Link
                        className={classes.listLink}
                        onClick={logout}
                    >
                        <ExitToAppIcon className={classes.dropdownIcons} />
                        Signout
                    </Link>
                </ListItem>
                </> 
            }
        </>

    )
};



export default LoginList;