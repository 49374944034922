import { createSlice } from '@reduxjs/toolkit';
import searchApi from './search-service';
import { postMessage } from 'store/slices/messages/messages'; 
import { GetToken } from 'CustomAuthProvider';

export const initialState = {
  hasErrors: false,
  loading: false,
  results: [],
  elapsedTime: null 
}

const sliceName = 'searchFacets';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFacetsStarting: state => {
      state.hasErrors = false;
      state.loading = true;
    },
    setFacetsSuccess: (state, { payload }) => {
      const { elapsedTime, results } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.results = results;  
      state.elapsedTime = elapsedTime;
    },
    setFacetsFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setFacetsStarting, setFacetsSuccess, setFacetsFailure } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer

export const getFacets = () => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setFacetsStarting());

      const tokenPromise = GetToken();          
      const token = await tokenPromise;        
      const facet = "ProjectMarketSegmentName";

      const results = await searchApi.getFacet( 
        "projects-index",
        facet,
        token.idToken 
      ); 

      dispatch(setFacetsSuccess({ results: results.result['@search.facets'][facet].filter(r => r.value.toLowerCase() !== "null") }));  
    } catch (error) { 
        dispatch(setFacetsFailure());
        postMessage({ type: "warning", text: "Search Error: " + error });
    }
  }
} 