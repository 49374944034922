import { createSlice } from '@reduxjs/toolkit';
import searchApi from 'store/slices/search/search-service';
import { postMessage } from 'store/slices/messages/messages';
import { GetToken } from 'CustomAuthProvider';

export const initialState = {
  hasErrors: false,
  loading: false,
  siblingProjects: [],
}

const sliceName = 'siblingProjects';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFetchingStarted: state => {
      state.hasErrors = false;
      state.loading = true;
    },
    setSiblingProjects: (state, { payload }) => {
      const { siblingProjects } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.siblingProjects = siblingProjects;
    },
    setFetchingFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setFetchingStarted, setSiblingProjects, setFetchingFailure } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer


export const fetchSiblingProjects = (referenceProject) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setFetchingStarted());
      if (!referenceProject) { throw Error("Can't fetch related projects from blank reference."); }
      const token = await GetToken();

      let queryString = "";
      // if (referenceProject.Contract) {
      //   queryString = "Contract:(\"" + referenceProject.Contract + "\")";
      // } 
      // else 
      if (referenceProject.ContractCode && referenceProject.ContractCode !== 'CC-') {
        queryString = "ContractCode:(\"" + referenceProject.ContractCode + "\")";
      } else if (referenceProject.ContractName) {
        queryString = "ContractName:(\"" + referenceProject.ContractName + "\")";
      } 
      else {
        throw Error("Can't fetch sibling projects without code. For now...");
      }
      queryString = encodeURIComponent(queryString);

      const results = await searchApi.getSearch(
        queryString,
        'projects-index',
        "",
        "",
        token.idToken,
        500,
        0,
        "",
        "",
      );

      const parsedResult = parseSearchResults(results);
      if (!parsedResult) {
        throw Error("Couldn't retrieve related projects");
      }
      dispatch(setSiblingProjects(parsedResult));
    } catch (error) {
      dispatch(setFetchingFailure());
      dispatch(postMessage({ type: "warning", text: "Search Error: " + error }));
    }
  }
}

export const clearSiblingProjects = () => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local. 
      dispatch(setSiblingProjects([]));
    } catch (error) {
      dispatch(postMessage({ type: "warning", text: "Related Contracts Error: " + error }));
    }
  }
}

//this is to parse out the somewhat ugly search results, this should probably be moved server sode
const parseSearchResults = results => {
  const parsed = {};
  if (results.primary && results.primary.length > 0) {
    const res = results.primary[0];
    if (res.properties && res.properties.elapsedTime) {
      parsed.elapsedTime = res.properties.elapsedTime;
    } else { console.log("couldn't gather elapsed time") }
    if (res.result) {
      const resDict = {};//this turns the results into a dict for fast lookup.
      //it takes advantage of the fact that modern javascript preserves property order.
      //If you log the object with chrome dev tools it will display alphabetically and
      //look out of order, however, if you call Object.Values(), you'll see the original
      //expected order.
      if (res.result.value && res.result.value.length > 0) {
        res.result.value.forEach(sr => {
          resDict[sr.ProjectId] = sr
        });
      } else { console.log("couldn't gather results into dictionary") }
      parsed.siblingProjects = resDict;
    } else { console.log("couldn't gather result") }
  }

  return parsed;
}