import React from "react";
// react components for routing our app without refresh
// nodejs library to set properties for components
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import styles from "./contractStyle.js"; 
import PieChart from "@material-ui/icons/PieChartOutlined";
import AccountBox from "@material-ui/icons/AccountBoxOutlined";
import Grid from '@material-ui/core/Grid';  
import RelatedProjectsCarousel from "components/Carousels/RelatedProjectsCarousel";
import RelatedContractsCarousel from "components/Carousels/RelatedContractsCarousel";
import { selector as relatedContractsSelector } from 'store/slices/contractPage/relatedContracts';
import { selector as childProjectsSelector } from 'store/slices/contractPage/childProjects';
import { useSelector } from "react-redux";
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import clsx from 'clsx';

const useStyles = makeStyles(styles);

export default function ContractMain(props) {

  const { item } = props;
  const classes = useStyles(); 

  const { relatedContracts } = useSelector(relatedContractsSelector);
  const { childProjects, loading: childProjectsFetching } = useSelector(childProjectsSelector);
  const childProjectsLength = Object.keys(childProjects).length;

  console.log(item)
  return (
    <>
      <Grid container alignItems="center" spacing={2}> 
        <Grid item >
          <Typography gutterBottom variant='h5' className={classes.mainLabelHeader}>{item.Code}</Typography>
          <Typography variant='h2' className={classes.selectedTitle}>
            {item.Name}   
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.cardDescriptionWhite}> 

      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}> 
          <Grid container wrap="nowrap" spacing={1}>
          <Grid item>
            <AccountBox className={classes.iconSmall} />
          </Grid>
          <Grid item xs>
            <Typography variant='h6' className={classes.smaller}>Client</Typography>
            <Typography className={classes.description}>
              {item.ClientName && item.ClientName.length > 300 ? item.ClientName.substring(0, 295) + ' ...' : item.ClientName || "No client name found"}
            </Typography>
          </Grid> 
        </Grid>

        </Grid>

        <Grid item xs={12} lg={6}> 
          <Grid container wrap="nowrap" spacing={1}>
           
          <Grid item>
            <ColorLensOutlinedIcon className={classes.iconSmall} />
          </Grid>
          <Grid item xs>
            <Typography variant='h6' className={classes.smaller}>Studio</Typography>
            <Typography className={classes.description}>
              {item.BusinessUnitName || "No studio found"}
            </Typography>
          </Grid>
        </Grid>

        </Grid>
        
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <Grid container wrap="nowrap" spacing={1}>
            <Grid item>
              <PieChart className={classes.iconSmall} />
            </Grid>
            <Grid item xs>
              <Typography variant='h6' className={classes.smaller}>Market Segment</Typography>
              <Typography className={classes.description}>
                {item.MarketSegment || "No market segment found"}
              </Typography>
            </Grid>
 
          </Grid>
        </Grid>
 
      </Grid>
      
        <Typography variant="body1" className={clsx(classes.descriptionStrong, classes.heading)} >
          Projects Under This Contract {(childProjects && !childProjectsFetching) ? "(" + (childProjectsLength === 500 ? "Top " : "") + childProjectsLength + ")" : ""}
        </Typography>
        <RelatedProjectsCarousel items={childProjects} loading={childProjectsFetching} numberOfCards={5} />

        <Typography variant="body1" className={clsx(classes.descriptionStrong, classes.heading)} >
          Similar Contracts
        </Typography>
        <RelatedContractsCarousel items={relatedContracts} numberOfCards={5} />

      </div>
    </>
  );
}
