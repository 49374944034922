import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { selector as msgSelector, dismissMessage } from 'store/slices/messages/messages';
import { useDispatch, useSelector } from 'react-redux'

//this is meant to be the singular message snackbar component for the entire application
//it's designed to connect to the messages store and display and allow for the dismissal of,
// the most recent message. It's imported fairly high level in the app (just above the router in the index)

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));


export default function CustomizedSnackbars() {
    const classes = useStyles();
    const { messages } = useSelector(msgSelector);
    let open = false;

    const msgArray = Object.values(messages);
    let currMessage = null;
    if (msgArray.length > 0) {
        open = true;
        currMessage = msgArray[0];
    }


    const dispatch = useDispatch();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(dismissMessage(currMessage))
    };


    if (!currMessage) { return null; }
    return (
        <Snackbar className={classes.root} open={open} onClose={handleClose}>
            <Alert onClose={handleClose} severity={currMessage.type}>{currMessage.text}</Alert>
        </Snackbar>
    );
}
