import { createSlice } from '@reduxjs/toolkit';
import searchApi from './search-service';
import { postMessage } from 'store/slices/messages/messages'; 
import { GetToken } from 'CustomAuthProvider';

export const initialState = {
  hasErrors: false,
  loading: false,
  results: [],
  elapsedTime: null 
}

const sliceName = 'searchCarousel';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSearchCarouselStarting: state => {
      state.hasErrors = false;
      state.loading = true;
    },
    setSearchCarouselSuccess: (state, { payload }) => {
      const { elapsedTime, results } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.results = results;  
      state.elapsedTime = elapsedTime;
    },
    setSearchCarouselFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setSearchCarouselStarting, setSearchCarouselSuccess, setSearchCarouselFailure } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer

export const getSearchCarousel = (identifier, searchFields) => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setSearchCarouselStarting());

      const tokenPromise = GetToken();          
      const token = await tokenPromise; 
      const currentState = getState();
      const search = currentState.searchQuery.searchQuery || "*";
     
      const results = await searchApi.getSearch(
        search,
        'contracts-index',
        "",
        "",
        token.idToken,
        20,
        0, 
        "",
        ""  
      );
      
      const parsedResults = parseSearchResults(results); 

      dispatch(setSearchCarouselSuccess(parsedResults));  
    } catch (error) { 
        dispatch(setSearchCarouselFailure());
        postMessage({ type: "warning", text: "Search Error: " + error });
    }
  }
}

//this is to parse out the somewhat ugly search results, this should probably be moved server sode
const parseSearchResults = results => {
   
  const parsed = {};
  if (results.primary && results.primary.length > 0) {
    const res = results.primary[0];
    if (res.properties && res.properties.elapsedTime) {
      parsed.elapsedTime = res.properties.elapsedTime;
    } else { console.log("couldn't gather elapsed time") }
    if (res.result) {
      const resDict = {};//this turns the results into a dict for fast lookup.
      //it takes advantage of the fact that modern javascript preserves property order.
      //If you log the object with chrome dev tools it will display alphabetically and
      //look out of order, however, if you call Object.Values(), you'll see the original
      //expected order.
      if (res.result.value && res.result.value.length > 0) {
        res.result.value.forEach(sr => {  
          resDict[sr.Id] = sr
        });
      } else { console.log("couldn't gather results into dictionary") }
      parsed.results = resDict; 
    } else { console.log("couldn't gather result") }
  }

  return parsed;
}