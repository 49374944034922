import React from 'react';
import ProjectPageComponent from 'components/views/ProjectPage/ProjectPage';
import { useDispatch } from 'react-redux';
import { getProject, clearProject } from 'store/slices/projectPage/selectedProject'; 

const ProjectPageController = props => {
    console.log("project page controller update");
    const identifier = props.match.params.id;

    const dispatch = useDispatch();

    React.useEffect(
        () => {
            dispatch(clearProject())
            dispatch(getProject(identifier)) 
        },
        [identifier,dispatch]
    )


    const Page = React.useMemo(
        () => <ProjectPageComponent />,
        []
    );
    return Page;
}

export default ProjectPageController;
