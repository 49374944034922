import React from "react";
// react components for routing our app without refresh
import Link from "@material-ui/core/Link";
// nodejs library to set properties for components
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import styles from "./projectStyle.js";
import Event from "@material-ui/icons/EventOutlined";
import PieChart from "@material-ui/icons/PieChartOutlined";
import AccountBox from "@material-ui/icons/AccountBoxOutlined";
import BusinessOutlined from "@material-ui/icons/BusinessOutlined"; 
import AssignmentTurnedInOutlined from "@material-ui/icons/AssignmentTurnedInOutlined";
import Grid from '@material-ui/core/Grid';
import RouterLink from 'components/routing/Link';
import DirectoryDisplay from 'components/DirectoryDisplay/DirectoryDisplay';  
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import { selector as siblingProjectsSelector } from 'store/slices/projectPage/siblingProjects';
import { useSelector } from 'react-redux'
import ContractIcon from '@material-ui/icons/EmojiEventsOutlined';
import FolderIcon from "@material-ui/icons/FolderOpen";
import RelatedProjectsCarousel from "components/Carousels/RelatedProjectsCarousel";
import clsx from 'clsx'; 

const useStyles = makeStyles(styles);

export default function ProjectMain(props) {

  const { item } = props;
  const classes = useStyles(); 
  const { siblingProjects, loading:siblingProjectsFetching } = useSelector(siblingProjectsSelector);
  const siblingProjectsLength = Object.keys(siblingProjects).length;

  return (
    <> 
      <Grid container alignItems="center" spacing={2}> 
        <Grid item >
          <Typography gutterBottom variant='h5' className={classes.mainLabelHeader}>{item.ProjectCode}</Typography>
          <Typography variant='h2' className={classes.selectedTitle}>
            {item.ProjectName}   
          </Typography>
        </Grid>
      </Grid> 
      
      <div className={classes.cardDescriptionWhite}>
      <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <BusinessOutlined className={classes.iconSmall} />
              </Grid>
              <Grid item xs> 
                <Typography variant='h6' className={classes.smaller}>Client</Typography> 
                <Typography className={classes.description}>
                  {item.ProjectClientName}
                </Typography>
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <ContractIcon className={classes.iconSmall} />
              </Grid>
              <Grid item xs> 
                  <Typography variant='h6' className={classes.smaller}>Contract</Typography> 
                <Typography className={classes.description}>
                  {item.ContractCode &&
                  <Link component={RouterLink} to={"/contract/" + item.Contract}>
                    {item.ContractName + " | " + item.ContractCode}
                  </Link>
                  }
                  {!item.ContractCode &&
                    "Not found"
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid> 
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <Grid container  wrap="nowrap" spacing={1}>
            <Grid item>
              <PieChart className={classes.iconSmall} />
            </Grid>
            <Grid item xs> 
              <Typography variant='h6' className={classes.smaller}>Market Segment</Typography> 
              <Typography className={classes.description}>
                {item.ProjectMarketSegmentName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container wrap="nowrap" spacing={1}>
            <Grid item>
              <ColorLensOutlinedIcon className={classes.iconSmall} />
            </Grid>
            <Grid item xs> 
                <Typography variant='h6' className={classes.smaller}>Studio</Typography> 
              <Typography className={classes.description}>
                {item.ProjectBusinessUnitName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <Grid container wrap="nowrap" spacing={1}>
          <Grid item>
            <FolderIcon className={classes.iconSmall} />
          </Grid>
          <Grid item xs> 
              <Typography variant='h6' className={classes.smaller}>Network Directory</Typography> 
            <DirectoryDisplay directory={item.Directory} hideCopyButton={true} />
          </Grid> 
        </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container wrap="nowrap" spacing={1}>
            <Grid item>
              <Event className={classes.iconSmall} />
            </Grid>
            <Grid item xs> 
                <Typography variant='h6' className={classes.smaller}>Project Dates</Typography> 
              <Typography className={classes.description}>
                {new Date(item.ProjectStartDate).toISOString().split('T')[0]} to {new Date(item.ProjectFinishDate).toISOString().split('T')[0]}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <Grid container wrap="nowrap" spacing={1}>
            <Grid item>
              <AccountBox className={classes.iconSmall} />
            </Grid>
            <Grid item xs> 
                <Typography variant='h6' className={classes.smaller}>Project Manager</Typography> 
              <Typography className={classes.description}>
                <span dangerouslySetInnerHTML={{
                  __html:
                    item['@search.highlights'] && item['@search.highlights'].ProjectManagerName ? item['@search.highlights'].ProjectManagerName : item.ProjectManagerName
                }}>
                </span>{", "}
                <span dangerouslySetInnerHTML={{
                  __html:
                    item['@search.highlights'] && item['@search.highlights'].ProjectManagerHomeOrgName ? item['@search.highlights'].ProjectManagerHomeOrgName : item.ProjectManagerHomeOrgName
                }}>
                </span>
              </Typography>
 
                <>
                  <Typography variant='h6' className={classes.smaller}>Principal In Charge</Typography>
                  <Typography className={classes.description}>
                    <span dangerouslySetInnerHTML={{
                      __html:
                        item['@search.highlights'] && item['@search.highlights'].ProjectDirectorName ? item['@search.highlights'].ProjectDirectorName : item.ProjectDirectorName
                    }}>
                    </span>{", "}
                    <span dangerouslySetInnerHTML={{
                      __html:
                        item['@search.highlights'] && item['@search.highlights'].ProjectDirectorHomeOrgName ? item['@search.highlights'].ProjectDirectorHomeOrgName : item.ProjectDirectorHomeOrgName
                    }}>
                    </span>
                  </Typography>

                </> 
                <>
                  <Typography variant='h6' className={classes.smaller}>Project Organization</Typography>
                  <Typography className={classes.description}>
                    <span dangerouslySetInnerHTML={{
                      __html:
                        item['@search.highlights'] && item['@search.highlights'].ProjectOrgName ? item['@search.highlights'].ProjectOrgName : item.ProjectOrgName
                    }}>
                    </span>
                  </Typography>
                </>  
                <>
                  <Typography variant='h6' className={classes.smaller}>Related Projects</Typography>
                  <Typography className={classes.description}>
                    {item.RelatedProjects.length === 0 &&
                      "No related projects found"
                    }
                    {item.RelatedProjects.map((related, index, array) => {
                      return (
                        <Link key={index} component={RouterLink} to={"/Project/" + related.Key} className={classes.link}>
                          {related.Value}<br></br>
                        </Link>
                      )
                    })
                    }
                  </Typography>
                </> 
            </Grid>
           </Grid>
        </Grid>

      <Grid item xs={12} lg={6}>
          <Grid container wrap="nowrap" spacing={1}> 
            <Grid item>
              <AssignmentTurnedInOutlined className={classes.iconSmall} />
            </Grid>
            <Grid item xs> 
                <Typography variant='h6' className={classes.smaller}>Task Managers</Typography> 
              <Typography className={classes.description}>
                <span dangerouslySetInnerHTML={{
                  __html:
                    item['@search.highlights'] && item['@search.highlights'].TaskManagers ? item['@search.highlights'].TaskManagers[0] : (item.TaskManagers) ? item.TaskManagers : "No task managers found"
                }}>
                </span>
              </Typography>
 
                <>
                  <Typography variant='h6' className={classes.smaller}>Task Organizations</Typography>
                  <Typography className={classes.description}>
                    <span dangerouslySetInnerHTML={{
                      __html:
                        item['@search.highlights'] && item['@search.highlights'].TaskOrgs ? item['@search.highlights'].TaskOrgs[0] : (item.TaskOrgs) ? item.TaskOrgs : "No task organizations found "
                    }}>
                    </span>v
                </Typography>

                  <Typography variant='h6' className={classes.smaller}>Task Resources</Typography>
                  <Typography className={classes.description}>
                    <span dangerouslySetInnerHTML={{
                      __html:
                        item['@search.highlights'] && item['@search.highlights'].Resources ? item['@search.highlights'].Resources[0] : (item.Resources) ? item.Resources : "No resources found "
                    }}>
                    </span>
                  </Typography>
                </> 
            </Grid>
          </Grid>
        </Grid>

     </Grid>

        <Typography variant="body1" className={clsx(classes.descriptionStrong, classes.heading)} >
          {"Other Projects From The Same Contract "} {(siblingProjects && !siblingProjectsFetching) ? "(" + (siblingProjectsLength === 500 ? "Top " : "") + siblingProjectsLength + ")" : "0"} 
        </Typography>
        <RelatedProjectsCarousel items={siblingProjects} loading={siblingProjectsFetching} numberOfCards={5} />

      </div>
    </>
  );
}
