import React from 'react';
import Card from '@material-ui/core/Card';
import CardBody from "components/Card/CardBody.js"; 
import { Link as RouterLink } from "react-router-dom"; 
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';  

const useStyles = makeStyles(theme => ({
    card: {
        padding: 15
    },
    cardBody: {
        padding: 0
    },
    cardCategory: {
        marginTop: "10px",
        "& svg": {
            position: "relative",
            top: "8px"
        }
    },
    cardDescription: {
        color: theme.palette.grey[500],
        fontSize: 14
    },
    iconSmall: {
        fontSize: '1.3em',
        marginBottom: '-4px',
        marginRight: '5px',
        color: "#4d5156",
    },
    iconLabel: {
        color: "#4d5156",
        fontWeight: 400,
        fontSize: '1.1em'
    },
    selectedTitle: {
        margin: "0rem 0 0 !important",
        marginBottom: 20,
        minHeight: "50px",
        "& a": {
            textDecoration: 'none'
        },
        fontWeight: 400, 
        color: '#4d5156'
    },
    minumumTitleSpacer: {
        minHeight: 100
    },
}));

const ProjectCard = props => {
    const classes = useStyles(props); 
    const project = props.project;

    console.log(props.project)
    return (
        <Card variant="outlined" className={classes.card}>
            <CardBody className={classes.cardBody}>
                <Typography variant="caption" display="block">
                    {project.ProjectCode}
                </Typography> 
                
                <Typography className={classes.selectedTitle + " " + classes.minumumTitleSpacer}>
                    <Link component={RouterLink} to={"/project/" + project.ProjectId}>
                        {project.ProjectName.length > 45 ? project.ProjectName.substring(0, 45) + "..." : project.ProjectName} 
                    </Link>
                </Typography>
 
            </CardBody>
        </Card>
    );
}

export default ProjectCard;