
//the list of all potential permissions that different roles might have
//and that permission's title / name / identifier
const perms = {
    getProjects: "projects:get",
    createProjects: "projects:create",
    deleteProject: "projects:delete",
    getUsers: "users:get",
    changeRole: "role:change",
}

//our different role definitions.
const Visitor = {
    static: [//static permissions are basically binary, they can either do it, or not.
        perms.getProjects,
    ]
};

const User = {
    static: [
        perms.getProjects,
        perms.createProjects,
    ]
};

const Admin = {
    static: [
        perms.getProjects,
        perms.createProjects,
        perms.getUsers,
    ],
    dynamic: {//dynamic permissions are based on some information, 
        //maybe their general role allows them to delete projects,
        //but only if they're the project owner. Dynamic permissions
        // are for handling these situations that depend on more than just role.
        
        //In this case we're checking to see if we can change a user's role,
        // but we don't want to allow an admin to elevate
        //a user past themselves to owner. 


        // [perms.changeRole]: ({ usersCurrentRole, proposedRole }) => {
        //     const reqRank = 1;//lower is more powerful
        //     const usrRank = roleArray.indexOf(usersCurrentRole);
        //     console.log(reqRank)
        //     console.log(usrRank)
        //     if (reqRank > usrRank) { return false; }//if our requestor is lower ranked than our user don't do anything.

        //     const propRank = roleArray.indexOf(proposedRole);
        //     console.log(propRank)

        //     if (propRank == -1) { return false };//if our requestor is trying an invalid role
        //     if (propRank < reqRank) { return false };//if our requestor is trying to elevate someone else's rank above their own.

        //     return true;
        // },
        // [perms.deleteProject]: ({ userId, postOwnerId }) => {
        //     if (!userId || !postOwnerId) return false;
        //     return userId === postOwnerId;
        // }
    }
};

//owners just get all the permissions as static permissions
const Owner = {
    static: Object.values(perms),
};

//I know this is basically just an array, but it's manually
//defined to emphasize the importance of order as it may be
//used to compare relative permissions (i.e. you can change
//someone's role, up to your level, but not past it).

const roleOrder = {
    Visitor,
    User,
    Admin,
    Owner,
};

module.exports = {
    permissions:perms,
    roles:roleOrder
};

