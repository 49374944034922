
import React from 'react';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { mlAuto, whiteColor, title } from 'components/themeStyle';
import { setSearchText, searchSelector } from 'store/slices/search/searchText';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { selector as suggestionsSelector, getSuggestions } from 'store/slices/search/searchSuggestions';
import { setSelected } from 'store/slices/search/searchSelected';
import Business from "@material-ui/icons/BusinessOutlined";
import TurnedInNotOutlined from "@material-ui/icons/TurnedInNotOutlined";
import { useHistory } from 'react-router-dom';
import { pushPage } from 'pages/_utils';

const useACStyles = makeStyles(theme => ({

    inputRoot: {
        color: theme.palette.grey[600],
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grey[300]
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ` + theme.palette.grey[400]
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ` + theme.palette.grey[400]
        },
        marginBottom: '-10px'//this is where the helper text would be
    },

}));
const useStyles = makeStyles(theme => ({
    mlAuto,
    link: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    title: {
        ...title,
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        color: whiteColor,
        textDecoration: "none"
    },
    inputLabel: {
        color: theme.palette.grey[400],
        '&.Mui-focused': {
            color: theme.palette.grey[400],
        }
    },
    searchIcon: {
        width: "20px",
        height: "20px",
        color: theme.palette.grey[400]
    },
    formControl: {
        margin: "0 !important",
        paddingTop: "7px",
        paddingBottom: "7px"
    },
    loader: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    suggestions: {
        zIndex: 1,
        position: 'absolute',
        border: '1px solid #dadce0',
        backgroundColor: 'white',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        width: 'inherit',
        marginLeft: -9,
        top: 0
    },
    suggestion: {
        paddingLeft: 12.5,
        paddingRight: 12.5,
        paddingTop: 10,
        paddingBottom: 5,
        fontSize: '14px',
        fontWeight: 40,
        "&:hover": {
            backgroundColor: "none"
        }
    },
    suggestionText: {
        paddingLeft: 5
    },
    suggestionHeading: {
        color: 'black'
    },
    suggestionsShown: {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px'
    },
}));

const SearchPageSearchBar = props => {
    const classes = useStyles();
    const autoCompleteClasses = useACStyles();
    const dispatch = useDispatch();
    const { searchText } = useSelector(searchSelector);
    const { results: suggestions, loading: suggestionsLoading } = useSelector(suggestionsSelector);
    const history = useHistory();

    const clear = () => {
        dispatch(setSearchText(""))
        dispatch(getSuggestions())
    }

    const onSearchTextHandler = (event, value, reason) => {
        if (reason === 'input') {
            dispatch(setSearchText(event.target.value))             
            dispatch(getSuggestions())
        }
        if (reason === 'clear') {
            clear();
        }
    }
    const onKeyDownHandler = event => {
        if (event.key === 'Enter') {
            event.target.blur()
            dispatch(setSelected(null))
            executeSearch();
        }
    }
    const executeSearch = (newText) => {
        pushPage(history, '/search', { searchQuery: newText || searchText, page: 1 });
    }
    const onSuggestionSelect = (event, option, reason) => {

        if (option && reason === 'select-option') {
            dispatch(setSelected(option.ProjectId))
            executeSearch(option.ProjectName);
        }
    };
    return (
        <Grid container className={classes.mlAuto}>
            <Grid item xs className={classes.textFieldWrapper}>
                <Autocomplete
                    disableClearable
                    getOptionLabel={(option) => option ? option.ProjectName ? option.ProjectName : "" : ""}
                    classes={autoCompleteClasses}
                    options={suggestions}
                    loading={suggestionsLoading}
                    onChange={onSuggestionSelect}
                    freeSolo
                    inputValue={searchText || ""}
                    onInputChange={onSearchTextHandler}
                    noOptionsText={"Start typing to see suggestions..."}
                    renderOption={(option) => {
                        return (
                            <Grid container className={classes.suggestion}>
                                <Grid item xs={2} sm={1}>
                                    {option.TextPlain === option.ProjectName ? <TurnedInNotOutlined color="disabled" /> : <Business color="disabled" /> }
                                </Grid>
                                <Grid item xs={10}>
                                    <Grid container className={classes.suggestionText}>
                                        <Grid item xs={12}>
                                            <span className={classes.suggestionHeading} dangerouslySetInnerHTML={{ __html: option.TextMarkup }}></span>
                                        </Grid>
                                        <Grid item xs={11}>
                                            {option.SecondaryText}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                                className: classes.inputLabel
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <IconButton size="small" onClick={(e) => { executeSearch(); e.target.blur(); }} >
                                            <SearchIcon className={classes.searchIcon} />
                                        </IconButton>
                                        <IconButton size="small" onClick={(e) => { clear(); e.target.blur(); }} >
                                            <CloseIcon className={classes.searchIcon} />
                                        </IconButton>
                                        {/* {params.InputProps.endAdornment} */}
                                    </>
                                )
                            }}
                            variant="outlined"
                            placeholder="Search"
                            onKeyDown={onKeyDownHandler}
                            label=""
                        />
                    )}
                >
                </Autocomplete>
            </Grid>


        </Grid>
    );
}
export default SearchPageSearchBar;