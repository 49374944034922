import React from 'react';
import ContractPageComponent from 'components/views/ContractPage/ContractPage';
import { useDispatch } from 'react-redux';
import {
    fetchContract,
    //    clearContract, 
    //  selectContract
} from 'store/slices/contractPage/selectedContract';

const ContractPageController = props => {
    console.log("contract page controller update");
    const identifier = props.match.params.id;

    const dispatch = useDispatch();

    React.useEffect(
        () => {
            //if(projectsStore[identifier] === null)
            //dispatch(selectProject(projectsStore[identifier]))
            //else
            dispatch(fetchContract(identifier));
        },
        [identifier, dispatch]
    )


    const Page = React.useMemo(
        () => <ContractPageComponent />,
        []
    );
    return Page;
}

export default ContractPageController;
