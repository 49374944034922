import getFetchOptions from 'store/utils/fetchOptions';
const baseAPI = '/api/';


const userService = {
  /**
   * 
   * @param {*} idToken wants an idToken in the form {rawIdToken: ''}
   * @returns 
   */
  async loginOrSignup(idToken) {
    try {
      console.log('logging user in or signing up');
      if (!idToken) { throw Error('invalid idToken can\'t fetch user info'); }
      const response = await fetch(`${baseAPI}/users/login`,
        getFetchOptions('GET', 'application/json', null, true, idToken));
      console.log('user logged in ');

      if (!response.ok) {
        console.log('fetch failed - response not ok ')
        //console.log(response)
        throw Error(response.status + " " + response.statusText);
      }
      const parsed = await response.json();
      parsed.given = parsed.name || "";
      return parsed;
    }
    catch (err) {
      throw err;
    }
  },

  async getUsers(idToken) {
    try {
      if (!idToken) { throw Error('invalid idToken can\'t fetch'); }
      console.log('fetching user list')
      console.log(idToken)
      const response = await fetch(`${baseAPI}/users/`,
        getFetchOptions('GET', 'application/json', null, true, idToken))

      if (!response.ok) {
        const err = Error(response.status + " " + response.statusText);
        err.code = response.status;
        return { users: null, err: err }
      }
      const users = await response.json();
      return { users: users, err: null };
    }
    catch (err) {
      return { users: null, err: err };
    }
  },
  async changeRole(idToken, proposedUserId, proposedRole) {
    try {
      if (!idToken) { throw Error('invalid idToken can\'t fetch'); }
      console.log('fetching user list')
      const response = await fetch(`${baseAPI}/users/` + proposedUserId + '/' + proposedRole,
        getFetchOptions('PATCH', 'application/json', null, true, idToken))

      if (!response.ok) {
        throw Error(response.status + " " + response.statusText);
      }
      const resp = await response.json();
      return resp;
    }
    catch (err) {
      throw err;
    }
  },


};

export default userService;