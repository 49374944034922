import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
    MdContentCopy,
    MdEdit,
} from 'react-icons/md';
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton';
import copy from 'copy-to-clipboard';
import { postMessage } from 'store/slices/messages/messages';
import { useDispatch } from 'react-redux' 

const useStyles = makeStyles(theme => ({
    description: {
        overflow: "hidden",
        margin: "0",
        "& p": {
            color: '#4d5156',
            fontSize: "14px"
        },
        color: "#4d5156",
        fontSize: "14px",
        fontWeight: 400
    },
    clickable: {
        color: "#9c27b0"
    },
    hoverPointer: {
        cursor: 'pointer',
    },
}));

const editEnabled = false;

const DirectoryDisplay = props => {
    const { directory, hideCopyButton } = props;
    const classes = useStyles(props);
    const dispatch = useDispatch();

    const userCopies = () => {
        copy(directory);
        dispatch(postMessage({ type: 'success', text: 'Copied to clipboard!', timeout: 3000 }));
    };
    return (
        <Grid container alignItems="center" spacing={1} >

            {/* groups the two buttons together */}
            {directory && (!hideCopyButton || editEnabled) &&
                <Grid item >
                    <Grid container>
                        {!hideCopyButton &&
                            <Grid item>
                                <IconButton
                                    size="small"
                                    aria-label="copy to clipboard"
                                    component="span"
                                    onClick={userCopies}>
                                    <MdContentCopy />
                                </IconButton>
                            </Grid>
                        }
                        {editEnabled &&
                            <Grid item>
                                <IconButton size="small" aria-label="copy to clipboard" component="span">
                                    <MdEdit />
                                </IconButton>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }


            {/* our text as it's own item to increase spacing */}
            <Grid item >
                {directory ?
                    <Typography  className={clsx(classes.description, classes.hoverPointer, classes.clickable)} onClick={userCopies}>
                        {directory}
                    </Typography>
                    :
                    <Typography className={classes.description} >
                        {"Oops, we're also having trouble finding that folder!"}
                    </Typography>
                }

            </Grid>


        </Grid>

    );
}


export default DirectoryDisplay;