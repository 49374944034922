
import React from 'react';
import SearchIcon from "@material-ui/icons/Search";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import { mlAuto, whiteColor, title } from 'components/themeStyle';
import { setSearchText, searchSelector } from 'store/slices/search/searchText';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { selector as suggestionsSelector, getSuggestions } from 'store/slices/search/searchSuggestions';
import { authSelector, loginButton } from 'store/slices/auth/auth';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setSelected } from 'store/slices/search/searchSelected';
import Business from "@material-ui/icons/BusinessOutlined";
import AccountBox from "@material-ui/icons/AccountBoxOutlined";
import { useHistory } from 'react-router-dom';
import { pushPage } from 'pages/_utils';

const useACStyles = makeStyles(theme => ({
    inputRoot: {
        color: "white",
        "&.MuiInput-underline": {
            "&:before": {
                borderBottom: `1px solid ` + theme.palette.grey[400]
            },
            "&:hover:before": {
                borderBottom: `1px solid ` + theme.palette.grey[400]
            },
            "&:hover:after": {
                borderBottom: `1px solid white`
            },
            "&:after": {
                borderBottom: `1px solid white`
            },
        },
    },

}));

const useStyles = makeStyles(theme => ({
    mlAuto,
    link: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    title: {
        ...title,
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        color: whiteColor,
        textDecoration: "none"
    },
    inputLabel: {
        color: theme.palette.grey[400],
        '&.Mui-focused': {
            color: theme.palette.grey[400],
        }
    },

    searchIcon: {
        width: "20px",
        height: "20px",
        color: "inherit"
    },
    formControl: {
        margin: "0 !important",
        paddingTop: "7px",
        paddingBottom: "7px"
    },
    loader: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    suggestions: {
        zIndex: 1,
        position: 'absolute',
        border: '1px solid #dadce0',
        backgroundColor: 'white',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        width: 'inherit',
        marginLeft: -9,
        top: 77
    },
    suggestion: {
        paddingLeft: 12.5,
        paddingRight: 12.5,
        paddingTop: 10,
        paddingBottom: 5,
        fontSize: '14px',
        fontWeight: 40,
        "&:hover": {
            backgroundColor: "#dadce0"
        }
    },
    suggestionText: {
        paddingLeft: 5
    },
    suggestionHeading: {
        color: 'black'
    },
    suggestionsShown: {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px'
    },
}));

const SearchBar = props => {
    const classes = useStyles();
    const autoCompleteClasses = useACStyles();
    const dispatch = useDispatch();
    const { searchText } = useSelector(searchSelector);
    const { results: suggestions, loading: suggestionsLoading } = useSelector(suggestionsSelector);
    const { authenticated, loggingInToAuth, loggingInToApp } = useSelector(authSelector);
    const loggingIn = loggingInToAuth || loggingInToApp;
    const history = useHistory();

    const onSearchTextHandler = (event, value, reason) => {
        if (reason === 'input') {
            dispatch(setSearchText(event.target.value));
            dispatch(getSuggestions());
        }
        if (reason === 'clear') {
            dispatch(setSearchText(""));
            dispatch(getSuggestions());
        }
    }
    const onKeyDownHandler = event => {
        if (event.key === 'Enter') {
            executeSearch();
        }
    }
    const executeSearch = (newText) => {
        pushPage(history, '/search', { searchQuery: searchText, page: 1 });
    }
    const onSuggestionChange = (event, option, reason) => {

        if (reason === 'select-option' && option) {
            dispatch(setSearchText(option.ProjectName));
            dispatch(setSelected(option.ProjectId));
            executeSearch(option.ProjectName);
        }
    };
    const onLogin = () => {
        dispatch(loginButton());
    }
    return (
        <>
            {authenticated &&
                <>
                    <Typography className={classes.title} variant="h1" >
                        Start Searching Now
                    </Typography>
                    <Grid container className={classes.mlAuto}>
                        <Grid item xs className={classes.textFieldWrapper}>
                            <Autocomplete
                                getOptionLabel={(option) => option.ProjectName}
                                classes={autoCompleteClasses}
                                options={suggestions}
                                freeSolo
                                loading={suggestionsLoading}
                                onChange={onSuggestionChange}
                                onClose={(e) => { console.log('close'); e.target.blur() }}
                                inputValue={searchText}
                                onInputChange={onSearchTextHandler}
                                noOptionsText={"Start typing to see suggestions..."}                           
                                renderOption={(option) => {
                                    return (
                                        <Grid container className={classes.suggestion}>
                                            <Grid item xs={1}>
                                                {option.TextPlain === option.ProjectName ? <Business color="disabled" /> : <AccountBox color="disabled" />}
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Grid container className={classes.suggestionText}>
                                                    <Grid item xs={12}>
                                                        <span className={classes.suggestionHeading} dangerouslySetInnerHTML={{ __html: option.TextMarkup }}></span>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        {option.SecondaryText}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        InputLabelProps={{
                                            className: classes.inputLabel
                                        }}
                                        onKeyDown={onKeyDownHandler}
                                        label="Find Projects"
                                    />
                                )}
                            >
                            </Autocomplete>
                        </Grid>
                        <Grid item xs="auto">
                            <Button color="white" onClick={executeSearch} justIcon round>
                                <SearchIcon className={classes.searchIcon} />
                            </Button>
                        </Grid>

                    </Grid>

                </>
            }
            {!authenticated &&
                <>
                    {loggingIn &&
                        <>
                            <Typography className={classes.title} variant="h1" >
                                {"Logging In"}
                            </Typography>
                            <CircularProgress className={classes.loader} color="inherit" size={40} />
                        </>
                    }
                    {!loggingIn &&
                        <Typography className={classes.title} variant="h1" >
                            <Link className={classes.link} onClick={onLogin}> Log In</Link> To Get Started
                    </Typography>
                    }
                </>
            }

        </>

    );
}
export default SearchBar;