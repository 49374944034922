import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import style from "./sectionResultsStyle.js";
import Pagination from "components/Pagination/Pagination.js"
import paginate from "./paginate.js"
import { selector as searchResultsSelector } from 'store/slices/search/searchResults';
import { useSelector } from 'react-redux'
import { pushPage } from 'pages/_utils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(style);

const SectionPagination = props => {
  const classes = useStyles();
  const history = useHistory();
  const changePage = pageNumber => {
    pushPage(history, '/search', { page: pageNumber });
  }

  const { count, resultsPerPage, currentPage, } = useSelector(searchResultsSelector);

  let p = paginate(parseInt(count), parseInt(currentPage), parseInt(resultsPerPage), 6)

  let pages = p.pages.map((r, i) => {
    return {
      text: r, active: r.toString() === p.currentPage.toString(), onClick: (page) => {
        changePage(page);
      }
    }
  })

  pages.unshift({
    text: "PREV",
    disabled: p.currentPage.toString() === "1",
    onClick: (page) => {
      changePage(parseInt(p.currentPage) - 1);
    }
  })
  pages.push({
    text: "NEXT",
    disabled: p.currentPage.toString() === p.endPage.toString(),
    onClick: (page) => {
      changePage(parseInt(p.currentPage) + 1);
    }
  })

  return (
    <GridContainer justify="center" className={classes.paginationSection}>
      <GridItem xs={12} sm={8} md={8}>
        <Pagination pages={pages} />
      </GridItem>
    </GridContainer>
  );
}

export default SectionPagination;