import postsReducer from './posts/posts';
import postReducer from './posts/post';
import displayNumReducer from './posts/displayNum';
import authReducer from './auth/auth';
import usersReducer from './auth/users';
import messagesReducer from './messages/messages';
import searchTextReducer from './search/searchText';
import searchFieldsReducer from './search/searchFields';
import searchResultsReducer from './search/searchResults';
import searchSuggestionsReducer from './search/searchSuggestions';
import searchResultsPageReducer from './search/searchResultsPage';
import searchSelectedReducer from './search/searchSelected';
import searchMoreLikeThisReducer from './search/searchMoreLikeThis';
import searchCarouselReducer from './search/searchCarousel';
import searchRelatedReducer from './search/searchRelated';
import searchFacetsReducer from './search/searchFacets';
import searchQueryReducer from './search/searchResultsQuery';
import selectedContractReducer from './contractPage/selectedContract';
import relatedContractsReducer from './contractPage/relatedContracts';
import childProjectsReducer from './contractPage/childProjects';
import siblingProjectsReducer from './projectPage/siblingProjects';
import selectedProjectReducer from './projectPage/selectedProject';

const customReducers = {
  posts: postsReducer,
  post: postReducer,
  displayNum: displayNumReducer,
  auth: authReducer,
  users: usersReducer,
  searchText: searchTextReducer,
  searchFields: searchFieldsReducer,
  messages: messagesReducer,
  searchResults: searchResultsReducer,
  searchSuggestions: searchSuggestionsReducer,
  searchResultsPage: searchResultsPageReducer,
  searchSelected: searchSelectedReducer,
  searchMoreLikeThis: searchMoreLikeThisReducer,
  searchRelated: searchRelatedReducer,
  searchFacets: searchFacetsReducer,
  searchQuery: searchQueryReducer,
  searchCarousel: searchCarouselReducer,
  selectedContract: selectedContractReducer,
  selectedProject: selectedProjectReducer,
  relatedContracts: relatedContractsReducer,
  childProjects:childProjectsReducer,
  siblingProjects:siblingProjectsReducer,
};

export default customReducers;
