import { createSlice } from '@reduxjs/toolkit';
import searchApi from './search-service';
import { postMessage } from 'store/slices/messages/messages'; 
//import { push } from 'connected-react-router';
import { setMoreLikeThis, clearMoreLikeThis } from 'store/slices/search/searchMoreLikeThis';
import { GetToken } from 'CustomAuthProvider';

export const initialState = {
  hasErrors: false,
  loading: false,
  results: [],
  elapsedTime: null,
  count: null,
  resultsPerPage: 8,
  currentPage: 1  
}

const sliceName = 'searchResults';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSearchStarting: state => {
      state.hasErrors = false;
      state.loading = true;
      state.results = [];
    },
    setSearchResultsSuccess: (state, { payload }) => {
      const { elapsedTime, count, results, currentPage } = payload;
      state.loading = false;
      state.hasErrors = false;
      state.results = results; 
      state.count = count;
      state.currentPage = currentPage;
      state.elapsedTime = elapsedTime;
    },
    setSearchResultsFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
})

export const { setSearchStarting, setSearchResultsSuccess, setSearchResultsFailure } = slice.actions
export const selector = state => state[sliceName];
export default slice.reducer

export const getSearch = () => {
  return async (dispatch, getState) => {
    try {// just an example, probs unnesecary for something local.
      dispatch(setSearchStarting());
      dispatch(clearMoreLikeThis())

      const tokenPromise = GetToken();      
      const token = await tokenPromise;  
      const currentState = getState();
      
      const page = currentState.searchResultsPage.page;             
      const search = currentState.searchQuery.searchQuery || "*";
      const selected = currentState.searchSelected.identifier;
        
      const highlightFields = "ProjectManagerName,ProjectManagerHomeOrgName,ProjectDirectorName,ProjectDirectorHomeOrgName,ProjectOrgName,ProjectMarketSegmentName,ProjectBusinessUnitName,Tasks,TaskManagers,Resources"
            
      const results = await searchApi.getSearch(
        search,
        'projects-index',
        "",
        "",
        token.idToken,
        currentState.searchResults.resultsPerPage,
        (page - 1) * currentState.searchResults.resultsPerPage, 
        highlightFields,
        ""
      );
 
      const parsedResults = parseSearchResults(results, selected);
      parsedResults.currentPage = page;
 
      dispatch(setSearchResultsSuccess(parsedResults));  
  
      if (selected) {   
        dispatch(setMoreLikeThis(selected)) 
      } else {  
        dispatch(setMoreLikeThis(Object.keys(parsedResults.results)[0]))
      }

    } catch (error) {
        dispatch(setSearchResultsFailure());
        dispatch(postMessage({ type: "warning", text: "Search Error: " + error }));
    }
  }
}

//this is to parse out the somewhat ugly search results, this should probably be moved server sode
const parseSearchResults = (results, selected) => {
   
  const parsed = {};
  if (results.primary && results.primary.length > 0) {
    const res = results.primary[0];
    if (res.properties && res.properties.elapsedTime) {
      parsed.elapsedTime = res.properties.elapsedTime;
    } else { console.log("couldn't gather elapsed time") }
    if (res.result) {
      if (res.result['@odata.count']) {
        parsed.count = res.result['@odata.count'];
      } else { console.log("couldn't gather count") }

      const resDict = {};//this turns the results into a dict for fast lookup.
      //it takes advantage of the fact that modern javascript preserves property order.
      //If you log the object with chrome dev tools it will display alphabetically and
      //look out of order, however, if you call Object.Values(), you'll see the original
      //expected order.

      if (res.result.value && res.result.value.length > 0) {
        // Make sure selected project from suggestions will display first in search results.   
        if (selected) { 
          moveToFront('ProjectId', selected, res.result.value);  
        }  

        res.result.value.forEach(sr => {           
          //sr.ProjectCode = sr.ProjectCode.replace('PC-', '')
          resDict[sr.ProjectId] = sr 
        });
      } else { console.log("couldn't gather results into dictionary") }
 
      parsed.results = resDict; 
    } else { console.log("couldn't gather result") }
  }

  return parsed;
}

/* eslint-disable */
const moveToFront = (property, value, col) => {
  col.reduce(function (prev, current, idx, obj) {
      if (current[property] != value) {
          return obj;
      } else {
          obj.unshift(obj[idx]);
          obj.splice(idx + 1, 1);
      }
  });
}