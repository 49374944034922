
import getFetchOptions from 'store/utils/fetchOptions';
const baseAPI = '/api/';

const getDocumentById = async (id, idToken) => {
  try {

    const documentType = "projects-index" 
    
    const response = await fetch(`${baseAPI}/search/documents/${documentType}/id/` + id,
      getFetchOptions('GET', 'application/json', null, true, idToken))
      .then(response => {
        if (!response.ok) {
          throw (Error(response.status + " " + response.statusText));
        }
        return response;
      });
    const parsedResponse = await response.json();
    return parsedResponse;
  }
  catch (err) {
    throw err;
  };
}

const getSearch = async (query, index, facets, filter, idToken, top, skip, highlight, searchFields, scoringProfile) => {
  try {
    let searchString = `?search=${query}&index=${index}&facets=${facets}&searchFields=${searchFields || ""}&filter=${filter || ""}&highlight=${highlight}&top=${top || 10}&skip=${skip || 0}`;
    if (scoringProfile) { searchString = searchString + "&scoringProfile=" + scoringProfile; }
 
    const response = await fetch(`${baseAPI}/search` + searchString,
      getFetchOptions('GET', 'application/json', null, true, idToken))
      .then(response => {
        if (!response.ok) {
          throw (Error(response.status + " " + response.statusText));
        }
        return response;
      });
    const parsedResponse = await response.json();
    return parsedResponse;
  }
  catch (err) {
    throw err;
  };
};

const getSuggestions = async (query, index, suggesterName, searchFields, select, top, idToken) => {
  try {
    const response = await fetch(
      `${baseAPI}/suggest?search=${query}&index=${index}&suggesterName=${suggesterName}&searchFields=${searchFields}&select=${select}&top=${top}`,
      getFetchOptions('GET', 'application/json', null, true, idToken)
    );
    if (!response.ok) {
      throw Error(response.status + " " + response.statusText);
    }
    const parsedResponse = await response.json();
    return parsedResponse;
  }
  catch (err) {
    throw (err)
  };
};

const getMoreLikeThis = async (index, documentId, searchFields, select, filter, top, idToken) => {
  try {
    const response = await fetch(
      `${baseAPI}/morelikethis?index=${index}&documentId=${documentId}&searchFields=${searchFields}&select=${select}&filter=${filter}&top=${top}`,
      getFetchOptions('GET', 'application/json', null, true, idToken)
    );
    if (!response.ok) {
      throw Error(response.status + " " + response.statusText);
    }
    const parsedResponse = await response.json();
    return parsedResponse;
  }
  catch (err) {
    throw (err)
  };
};

const searchService = {
  getSearch,
  getSuggestions,
  getMoreLikeThis,
  getDocumentById,
  getFacet(index, facet, idToken) {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${baseAPI}/facets?index=${index}&facet=${facet}`,
          getFetchOptions('GET', 'application/json', null, true, idToken))
          .then(response => {
            if (!response.ok) {
              reject(Error(response.status + " " + response.statusText));
            }
            return response;
          })
          .then(response => response.json())
          .then(json => resolve(json))
          .catch(err => reject(err));
      }
      catch (err) {
        reject(err)
      };
    })
  },

  getAutoComplete(q, index, suggesterName, searchFields, select, top, idToken) {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${baseAPI}/autocomplete?search=${q}&index=${index}&suggesterName=${suggesterName}&searchFields=${searchFields}&select=${select}&top=${top}`,
          getFetchOptions('GET', 'application/json', null, true, idToken))
          .then(response => {
            if (!response.ok) {
              reject(Error(response.status + " " + response.statusText));
            }
            return response;
          })
          .then(response => response.json())
          .then(json => resolve(json))
          .catch(err => reject(err));
      }
      catch (err) {
        reject(err)
      };
    })
  },


};

export default searchService;