import { createSlice } from '@reduxjs/toolkit';
import userService from './user-service';

import { postMessage } from 'store/slices/messages/messages';
import { loginRequest } from 'authConfig';
import { msalInstance } from 'CustomAuthProvider';

export const initialState = {
  authenticated: false,
  user: null,
  role: null,
  idToken: '',
  expiresOn: null,
  loggingInToAuth: false,
  loggingInToApp: false,
  hasErrors: false,
}

const sliceName = 'auth';

const authSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    startLoginToAuth: state => {
      state.authenticated = false;
      state.loggingInToAuth = true;
      state.loggingInToApp = true;
      state.hasErrors = false;
    },
    //this assumes that you will always immediately login to the app after logging in to the auth provider.
    loginToAuthSuccess: (state, { payload }) => {
      const { idToken, expiresOn } = payload;
      state.loggingInToAuth = false;
      state.loggingInToApp = true;
      state.idToken = idToken;
      state.expiresOn = expiresOn;
    },
    loginToAuthFailure: state => {
      state.authenticated = false;
      state.user = null;
      state.role = null;
      state.idToken = null;
      state.loggingInToAuth = false;
      state.loggingInToApp = false;
      state.hasErrors = true;
    },
    loginToAppSuccess: (state, { payload }) => {
      const { user } = payload;
      state.loggingInToAuth = false;
      state.loggingInToApp = false;
      state.user = user;
      state.role = user.role;
      state.authenticated = true;
    },
    loginToAppFailure: state => {
      state.authenticated = false;
      state.user = null;
      state.role = null;
      state.loggingInToAuth = false;
      state.loggingInToApp = false;
      state.hasErrors = true;
    },
    logout: state => {
      state.authenticated = false;
      state.user = null;
      state.role = null;
      state.idToken = null;
      state.loggingInToAuth = false;
      state.loggingInToApp = false;
    },
  },
})
//let's retrieve our actions from our returned slice and export them
export const {
  startLoginToAuth,
  loginToAuthSuccess,
  loginToAuthFailure,
  loginToAppSuccess,
  loginToAppFailure,
  logout,
} = authSlice.actions;

//let's export a selector for getting at our auth slice
export const authSelector = state => state[sliceName];

//let's export the reducer that came from our slice
export default authSlice.reducer;

//Now it's time for our Auth API.

export function loginToAppThunk() {
  return async (dispatch, getState) => {
    try {
      // console.log(getState())
      const idToken = getState()?.auth?.idToken;
      const resp = await userService.loginOrSignup({ rawIdToken: idToken });
      if (!resp) {
        const err = 'no response from app login';
        dispatch(postMessage({ type: 'warning', text: 'Login Issue: ' + err }));
        dispatch(loginToAppFailure());
        return;
      }
      dispatch(loginToAppSuccess({ user: resp }));
    } catch (err) {
      dispatch(postMessage({ type: 'warning', text: 'Login Issue: ' + err }));
      dispatch(loginToAppFailure());
    }
  }
}


export function loginButton() {
  return async dispatch => {
    try {
      const msalApp = msalInstance;

      msalApp.loginRedirect(loginRequest);
    } catch (err) {
      dispatch(loginToAuthFailure("" + err))
    }
  }
}

export function logoutButton() {
  return async dispatch => {
    const msalApp = msalInstance;
    msalApp.logout();
    dispatch(logout());
  }
}

